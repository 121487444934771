import React from 'react';
import {Form, InputGroup} from "react-bootstrap";
import {BsXCircle} from 'react-icons/bs';
import {notEmpty, safeTrim} from "../../util/stringUtil";

export type TextInputWithResetProps = {
	id?: string;
	value?: string | null;
	onChange?: (str: string) => any;
	onReset?: () => any;
};

function TextInputWithReset({id, value, onChange, onReset}: TextInputWithResetProps) {

	const reset = () => {
		onChange && onChange('');
		onReset && onReset();
	}

	return (
		<InputGroup>
			<Form.Control
				id={id}
				type="text"
				value={safeTrim(value)}
				onChange={(e) => onChange && onChange(e.target.value)}
			/>
			{
				notEmpty(value) &&
				<InputGroup.Text onClick={reset} className="cursor-pointer">
					<BsXCircle/>
				</InputGroup.Text>
			}
		</InputGroup>
	);
}

export default TextInputWithReset;
