import React from 'react';

export type DurationControlProps = {
	ms?: number | null;
};

function DurationControl({ms}: DurationControlProps) {
	if (!ms) {
		return <></>;
	}

	let secs = Math.floor(ms / 1000);
	ms -= secs * 1000;
	let mins = Math.floor(secs / 60);
	secs -= mins * 60;
	let hrs = Math.floor(mins / 60);
	mins -= hrs * 60;

	const items = [];
	if (hrs > 0) items.push(`${hrs}h`);
	if (mins > 0) items.push(`${mins}min`);
	if (secs > 0 && hrs === 0) items.push(`${secs}s`);
	if (ms > 0 && hrs == 0 && mins == 0) items.push(`${ms}ms`);

	const str = items.join(' ');

	return <span>{str}</span>;
}

export default DurationControl;
