import React from 'react';
import {ExecutionResult} from "../../type/ExecutionData";
import SmartTextBox from "./SmartTextBox";
import ExecutionResultWithProgressControl from "./ExecutionResultWithProgressControl";

export type ExecutionResultControlProps = {
	result?: ExecutionResult | null | undefined;
};

function ExecutionResultControl({result}: ExecutionResultControlProps) {
	if (!result) {
		return <>No results...</>;
	}
	return (
		<div>
			<div className="mb-2">
				<ExecutionResultWithProgressControl executionResult={result}/>
			</div>
			<div className="overflow-hidden">
				<SmartTextBox text={result.statusText}/>
			</div>
		</div>
	);
}

export default ExecutionResultControl;
