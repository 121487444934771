import {FormEvent, MouseEvent, useCallback, useEffect, useState} from 'react';
import {Button, Card, Col, Dropdown, Form, InputGroup, Row, Stack} from "react-bootstrap";
import {BasicComponentProps} from "../../../type/BasicComponentProps";
import {
	loadPluginInfo,
	loadPluginLinkToPivo,
	startPluginImportCustom,
	startPluginImportProductsFull,
	startPluginImportRegular,
	startSyncPlugins
} from "../../../util/restClient";
import ExecutionDataControl from "../../controls/ExecutionDataControl";
import {PluginInfo} from "../../../type/PluginInfo";
import {isValidInteger} from "../../../util/numberUtil";
import {ImportPluginJobArgument} from "../../../type/ImportPluginJobArgument";
import CustomPluginImportForm from "./CustomPluginImportForm";
import PluginSearchForm from "./PluginSearchForm";
import {BsArrowRepeat, BsInfoCircle} from 'react-icons/bs';
import {isEmpty} from "../../../util/stringUtil";
import {getCache, setCache} from "../../../util/cacheUtil";

export type PluginImportTabProps = BasicComponentProps & {};

function PluginImportTab({onResetAlerts, onAlert}: PluginImportTabProps) {
	const defaultSearchText = getCache('default-search-text', () => '');
	const [inputText, setInputText] = useState<string>(defaultSearchText);
	const [isInputTextValid, setIsInputTextValid] = useState<boolean>(false);
	const [pluginId, setPluginId] = useState<number | undefined>(
		isValidInteger(defaultSearchText) ? Number(defaultSearchText) : undefined
	);

	const [linkToPivo, setLinkToPivo] = useState<string | undefined>();
	const [pluginInfo, setPluginInfo] = useState<PluginInfo | undefined>();
	const [importForm, setImportForm] = useState<ImportPluginJobArgument | undefined>();
	const [showSearchForm, setShowSearchForm] = useState<boolean>(false);

	const syncPlugins = useCallback(
		(e: MouseEvent<HTMLButtonElement>) => {
			e.preventDefault();
			startSyncPlugins()
				.then(
					() => {
						onResetAlerts();
						onAlert({type: 'success', title: 'Success', message: 'Plugins synchronized.'})
					},
					(err) => onAlert({type: 'danger', title: 'Error', message: String(err)})
				);
		},
		[onAlert, onResetAlerts]
	)

	const loadInfo = useCallback(
		() => {
			if (pluginId === undefined) {
				setPluginInfo(undefined);
				return;
			}
			loadPluginInfo(pluginId)
				.then(
					setPluginInfo,
					(err) => {
						setPluginInfo(undefined);
						onAlert({type: 'danger', title: 'Error', message: String(err)});
					}
				);
		},
		[onAlert, pluginId]
	);

	const loadLinkToPivo = useCallback(
		() => {
			if (pluginId === undefined) {
				setLinkToPivo(undefined);
				return;
			}
			loadPluginLinkToPivo(pluginId)
				.then(
					setLinkToPivo,
					(err) => {
						setLinkToPivo(undefined);
						onAlert({type: 'danger', title: 'Error', message: String(err)});
					}
				);
		},
		[onAlert, pluginId]
	);

	const startImportRegular = (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		if (pluginId === undefined) return;
		startPluginImportRegular(pluginId)
			.then(
				() => {
					onResetAlerts();
					onAlert({type: 'success', title: 'Success', message: 'Regular plugin import started.'})
				},
				(err) => onAlert({type: 'danger', title: 'Error', message: String(err)})
			);
	}

	const startImportCustom = (arg: ImportPluginJobArgument) => {
		if (pluginId === undefined) return;
		startPluginImportCustom(arg)
			.then(
				() => {
					onResetAlerts();
					setImportForm(undefined);
					onAlert({type: 'success', title: 'Success', message: 'Custom plugin import started.'})
				},
				(err) => onAlert({type: 'danger', title: 'Error', message: String(err)})
			);
	}

	const startImportProductsFull = (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		if (pluginId === undefined) return;
		startPluginImportProductsFull(pluginId)
			.then(
				() => {
					onResetAlerts();
					onAlert({type: 'success', title: 'Success', message: 'Plugin refresh products started.'})
				},
				(err) => onAlert({type: 'danger', title: 'Error', message: String(err)})
			);
	}

	const showCustomImportForm = (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		if (pluginId === undefined) return;
		setImportForm({
			vipQueue: false,
			priorityMessage: true,
			workerParam: {pluginId: pluginId, importCommand: 'ImportAll', importMode: 'Auto'}
		});
	}

	useEffect(
		() => setIsInputTextValid(isValidInteger(inputText)),
		[inputText]
	);

	useEffect(
		() => {
			if (isInputTextValid) {
				setCache('default-search-text', String(pluginId));
				loadLinkToPivo()
				loadInfo();
			} else {
				setLinkToPivo(undefined);
				setPluginInfo(undefined);
			}
		},
		[pluginId, isInputTextValid]
	);

	useEffect(
		() => {
			const handle: NodeJS.Timer = setTimeout(loadInfo, 3000);
			return () => {
				window.clearTimeout(handle);
			};
		},
		[pluginInfo]
	);

	const loadInfoSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (isInputTextValid) {
			setPluginId(Number(inputText));
		} else {
			setPluginId(undefined);
		}
	}

	const pluginSearchConfirmed = (pluginId: number) => {
		setShowSearchForm(false);
		setPluginInfo(undefined);
		setInputText(String(pluginId));
		setPluginId(pluginId);
	}

	return (
		<div>
			<div className="mb-3 d-flex">
				<Form onSubmit={loadInfoSubmit}>
					<Form.Group className="mb-3" controlId="pluginId">
						<Stack direction="horizontal" gap={3} className="flex-wrap">
							<Form.Label className="m-0">Plugin ID:</Form.Label>
							<div>
								<InputGroup>
									<Button variant="secondary" onClick={() => setShowSearchForm(true)}>
										Search...
									</Button>
									<Form.Control
										type="text"
										autoFocus={true}
										value={inputText}
										onChange={(e) => setInputText(e.target.value)}
									/>
									<Button variant="success" type="submit" disabled={!isInputTextValid} className="d-flex align-items-center">
										<BsInfoCircle className="me-1"/>
										Load
									</Button>
								</InputGroup>
							</div>

							<Dropdown>
								<Dropdown.Toggle
									variant="primary"
									disabled={!isInputTextValid}
								>
									Execute...
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<Dropdown.Item onClick={startImportRegular}>Regular Import</Dropdown.Item>
									<Dropdown.Item onClick={startImportProductsFull}>Full Product Update</Dropdown.Item>
									<Dropdown.Divider/>
									<Dropdown.Item onClick={showCustomImportForm}>Custom Command...</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
							<Button variant="success" className="d-flex align-items-center" onClick={syncPlugins}>
								<BsArrowRepeat className="me-1"/>
								<div>Synchronize Plugins</div>
							</Button>
						</Stack>
					</Form.Group>
				</Form>
			</div>
			<div>
				{
					(!isEmpty(linkToPivo)) &&
					<p>
						Run integration validation in <a target="_blank" rel="noreferrer" href={linkToPivo}>P I V O</a>.
					</p>
				}
				{
					pluginInfo ?
						<>
							<h2 className="my-3">Active Schedules</h2>
							<div className="pb-3 border-bottom d-flex gap-2">
								{
									pluginInfo.activeSchedules.length > 0 ?
										pluginInfo.activeSchedules.map((schedule, index) => {
											return <Card className="p-2" key={index}>
												<div className="d-flex">
													<strong>Type:</strong>
													<span className="ms-2">{schedule.data.scheduleType}</span>
												</div>
												<div className="d-flex">
													<strong>Scheduling:</strong>
													<span className="ms-2">{schedule.schedulingDescription}</span>
												</div>
												<div className="d-flex">
													<strong>Job Name:</strong>
													<span className="ms-2">{schedule.data.job.name}</span>
												</div>
											</Card>
										})
										: "No active schedules."
								}
							</div>
							<Row>
								<Col md={6}>
									<h2 className="my-3">Regular Import Job</h2>
									<Card className="p-3">
										<ExecutionDataControl
											data={pluginInfo.lastRegularExecution}
											onRefreshRequested={loadInfo}
											onAlert={onAlert}
											onResetAlerts={onResetAlerts}
										/>
									</Card>
								</Col>
								<Col md={6}>
									<h2 className="my-3">Custom Import Job</h2>
									<Card className="p-3">
										<ExecutionDataControl
											data={pluginInfo.lastCustomExecution}
											onRefreshRequested={loadInfo}
											onAlert={onAlert}
											onResetAlerts={onResetAlerts}
										/>
									</Card>
								</Col>
							</Row>
						</>
						: <></>
				}
				{
					importForm ?
						<CustomPluginImportForm
							data={importForm}
							onAlert={onAlert}
							onResetAlerts={onResetAlerts}
							onCancelled={() => setImportForm(undefined)}
							onConfirmed={startImportCustom}/>
						: <></>
				}
				{
					showSearchForm ?
						<PluginSearchForm
							onAlert={onAlert}
							onResetAlerts={onResetAlerts}
							onCancelled={() => setShowSearchForm(false)}
							onConfirmed={pluginSearchConfirmed}/>
						: <></>
				}
			</div>
		</div>
	);
}

export default PluginImportTab;
