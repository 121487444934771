import {Paging, PagingRequest, Sorting, SortingField} from "../type/Paging";

export function sortingFieldToRequestFormat(s: SortingField): string | undefined {
	return s.desc ? `${s.name} DESC` : s.name;
}

export function sortingToRequestFormat(s: Sorting): string | undefined {
	return s.map((s: SortingField) => sortingFieldToRequestFormat(s)).join(',');
}

export function pagingToPagingRequest(p: Paging): PagingRequest {
	const result: PagingRequest = {
		page: p.page,
		size: p.size
	}
	if (p.search) {
		result.search = p.search;
	}
	if (p.sorting) {
		result.sorting = sortingToRequestFormat(p.sorting);
	}
	return result;
}
