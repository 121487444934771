import {useCallback, useEffect, useState} from 'react';
import {Container, Nav} from "react-bootstrap";
import Header from "./Header";
import AlertBox from "./controls/AlertBox";
import {UserAlert, UserAlerts} from "../type/UserAlert";
import {Auth, getAuthentication, setAuthentication} from "../util/authenticationUtil";
import LogInForm from "./LogInForm";
import DashboardTab from "./navigation/dashboard/DashboardTab";
import JobsTab from "./navigation/jobs/JobsTab";
import SchedulesTab from "./navigation/scheduling/SchedulesTab";
import ExecutionsTab from "./navigation/execution/ExecutionsTab";
import PluginImportTab from "./navigation/plugin-import/PluginImportTab";
import {loadAppVersion} from "../util/restClient";
import WorkersTab from "./navigation/workers/WorkersTab";
import AsyncCallbacksTab from "./navigation/async-callbacks/AsyncCallbacksTab";

function App() {
	const [activeTab, setActiveTab] = useState<string>('dashboard');
	const [alerts, setAlerts] = useState<UserAlerts>([]);
	const [auth, setAuth] = useState<Auth | null>(null);
	const [version, setVersion] = useState<string | undefined | null>(null);

	const refreshAuth = () => {
		setAuth(getAuthentication());
	};

	useEffect(refreshAuth, []);

	const loadVersion = () => {
		loadAppVersion()
			.then(
				(v: string) => {
					setVersion(v);
				},
				() => {
					setVersion(null);
				}
			);
	};

	useEffect(loadVersion, []);

	const logIn = (apiKey: string) => {
		setAuthentication({apiKey: apiKey});
		refreshAuth();
	};

	const logOut = () => {
		setAuthentication(null);
		refreshAuth();
	};

	const addAlert = useCallback(
		(alert: UserAlert) => setAlerts([...alerts, alert]),
		[setAlerts, alerts]
	);

	const resetAlerts = useCallback(
		() => {
			if (alerts.length > 0) setAlerts([]);
		},
		[setAlerts, alerts]
	);

	const removeAlert = useCallback(
		(alert: UserAlert) => {
			alerts.splice(alerts.indexOf(alert), 1);
			setAlerts([...alerts]);
		},
		[setAlerts, alerts]
	);

	const tabChanged = (t: string | null) => {
		setActiveTab(String(t));
	}

	const renderBody = () => {
		switch (activeTab) {
			case 'dashboard':
				return <DashboardTab onAlert={addAlert} onResetAlerts={resetAlerts}/>;
			case 'jobs':
				return <JobsTab onAlert={addAlert} onResetAlerts={resetAlerts}/>;
			case 'scheduling':
				return <SchedulesTab onAlert={addAlert} onResetAlerts={resetAlerts}/>;
			case 'execution':
				return <ExecutionsTab onAlert={addAlert} onResetAlerts={resetAlerts}/>;
			case 'workers':
				return <WorkersTab onAlert={addAlert} onResetAlerts={resetAlerts}/>;
			case 'callbacks':
				return <AsyncCallbacksTab onAlert={addAlert} onResetAlerts={resetAlerts}/>;
			case 'plugin-import':
				return <PluginImportTab onAlert={addAlert} onResetAlerts={resetAlerts}/>;
			default:
				return <>No tab selected</>;
		}
	}

	return (
		<Container className="p-3" fluid>
			<Header auth={auth} onLogOut={logOut} version={version} onAlert={addAlert} onResetAlerts={resetAlerts}/>
			{
				auth ?
					<>
						<Nav
							variant="tabs"
							defaultActiveKey={activeTab}
							onSelect={tabChanged}
							className="mb-3"
						>
							<Nav.Item>
								<Nav.Link eventKey="dashboard">Dashboard</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="jobs">Jobs</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="scheduling">Schedules</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="execution">Executions</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="workers">Workers</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="callbacks">Callbacks</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="plugin-import">Plugin Import</Nav.Link>
							</Nav.Item>
						</Nav>
						{renderBody()}
						<div className="mt-3 p-3 border-top text-right">
							Incomaker Job Manager &copy; {new Date().getFullYear()}
						</div>
					</>
					:
					<LogInForm onAlert={addAlert} onResetAlerts={resetAlerts} onLogIn={logIn}/>
			}
			<AlertBox alerts={alerts} onRemoveAlert={removeAlert} onResetAlerts={resetAlerts}/>
		</Container>
	);
}

export default App;
