import React from 'react';
import {Table} from "react-bootstrap";
import {Schedule, SchedulePage} from "../../../type/ScheduleData";
import Loading from "../../controls/Loading";
import {formatDateForHumans} from "../../../util/dateUtil";

export type ActiveSchedulesGridProps = {
	schedules: SchedulePage | null;
};

function ActiveSchedulesGrid({schedules}: ActiveSchedulesGridProps) {
	return (
		<div>
			<div className="m-2">Total Active Schedules: {schedules?.totalElements}</div>
			<div className="m-2">Upcoming scheduled jobs:</div>
			<Table responsive striped bordered hover>
				<thead>
					<tr>
						<th>Job</th>
						<th>Description</th>
						<th>Schedule Description</th>
						<th>Next Execution</th>
					</tr>
				</thead>
				<tbody>
					{
						(schedules === null) ? <tr>
								<td colSpan={4}><Loading/></td>
							</tr> :
							schedules.content.map((schedule: Schedule, index: number) => {
								return (
									<tr key={index}>
										<td>{schedule.data.job.name}</td>
										<td className="long-col">
											<pre>{schedule.data.job.description}</pre>
										</td>
										<td>{schedule.schedulingDescription}</td>
										<td>{formatDateForHumans(schedule.nextExecutionTime)}</td>
									</tr>
								);
							})
					}
				</tbody>
			</Table>
		</div>
	);
}

export default ActiveSchedulesGrid;
