import React from 'react';
import {Button, Col, Row, Stack} from "react-bootstrap";
import {ExecutionData} from "../../type/ExecutionData";
import ExecutionResultControl from "./ExecutionResultControl";
import {formatDateForHumans} from "../../util/dateUtil";
import DurationControl from "./DurationControl";
import {formatJsonForHumans} from "../../util/jsonUtil";
import ExecutionMessagesModal from "./ExecutionMessagesModal";
import {BasicComponentProps} from "../../type/BasicComponentProps";
import SmartTextBox from "./SmartTextBox";
import {BsArrowClockwise, BsXCircle} from 'react-icons/bs';
import {cancelExecution, runJobImmediately} from "../../util/restClient";
import {isStateRunning} from "./ExecutionStateControl";

export type ExecutionDataControlProps = BasicComponentProps & {
	data: ExecutionData | null | undefined;
	onRefreshRequested: () => any;
};

function ExecutionDataControl({data, onRefreshRequested, onAlert, onResetAlerts}: ExecutionDataControlProps) {

	const restartJob = (jobId?: number | null) => {
		if (jobId)
			runJobImmediately(jobId)
				.then(
					onRefreshRequested,
					(err) => onAlert({type: 'danger', title: 'Error', message: String(err)})
				)
	}

	const cancel = (executionId?: number | null) => {
		if (executionId)
			cancelExecution(executionId)
				.then(
					onRefreshRequested,
					(err) => onAlert({type: 'danger', title: 'Error', message: String(err)})
				)
	}

	if (!data) {
		return <>No data...</>;
	}

	return (
		<div>
			<Row>
				<Col>
					<div className="mb-2">
						<ExecutionResultControl result={data.result}/>
					</div>
					<div>
						<strong>Job Name:</strong> {data.schedule.job.name}
					</div>
					<div>
						<strong>Description:</strong>
						<div className="pt-1 ps-1">
							<SmartTextBox text={data.schedule.job.description}/>
						</div>
					</div>
					<div>
						<strong>Started:</strong> {formatDateForHumans(data.startTime)}
					</div>
					<div>
						<strong>Last State Update:</strong> {data.stateUpdateTime ? formatDateForHumans(data.stateUpdateTime) : 'Never'}
					</div>
					<div>
						<Stack direction="horizontal" gap={2}>
							<strong>Errors:</strong>
							{
								data.errors > 0 ?
									<ExecutionMessagesModal
										onAlert={onAlert}
										onResetAlerts={onResetAlerts}
										executionId={data.id}
										defaultMessageType="Error"
										buttonLabel={String(data.errors)}
									/>
									: data.errors
							}
						</Stack>
					</div>
					<div>
						<Stack direction="horizontal" gap={2}>
							<strong>Warnings:</strong>
							{
								data.warnings > 0 ?
									<ExecutionMessagesModal
										onAlert={onAlert}
										onResetAlerts={onResetAlerts}
										executionId={data.id}
										defaultMessageType="Warning"
										buttonLabel={String(data.warnings)}
									/>
									: data.warnings
							}
						</Stack>
					</div>
					{
						data.durationMs ? <div>
							<strong>Duration:</strong> <DurationControl ms={data.durationMs}/>
						</div> : <></>
					}
					{
						data.worker ? <>
							<div>
								<strong>Worker:</strong> {data.worker.name}
							</div>
							<div>
								<strong>Worker Started:</strong> {formatDateForHumans(data.startWorkerTime)}
							</div>
							<div>
								<strong>Work Duration:</strong> <DurationControl ms={data.durationWorkerMs}/>
							</div>
						</> : <></>
					}
				</Col>
				<Col>
					{
						data.arguments ?
							<div>
								<strong>Args:</strong>
								<div className="m-2">
									<pre className="code">{formatJsonForHumans(data.arguments)}</pre>
								</div>
							</div>
							: <></>
					}
				</Col>
			</Row>
			<div className="mt-2 d-flex align-items-center gap-2">
				<Button
					className="d-flex align-items-center"
					variant="success"
					disabled={isStateRunning(data.result.executionState)}
					onClick={() => restartJob(data.schedule.job.id)}
				>
					<BsArrowClockwise className="me-2"/>
					Restart
				</Button>
				{
					(isStateRunning(data.result.executionState)) &&
					<Button
						className="d-flex align-items-center"
						variant="warning"
						onClick={() => cancel(data.id)}
					>
						<BsXCircle className="me-2"/>
						Cancel
					</Button>
				}
			</div>
		</div>
	);
}

export default ExecutionDataControl;
