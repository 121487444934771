import React, {useEffect, useState} from 'react';
import {Button} from "react-bootstrap";
import {BasicComponentProps} from "../../../type/BasicComponentProps";
import {deleteWorker, loadWorkers} from "../../../util/restClient";
import {DEFAULT_PAGING, Paging} from "../../../type/Paging";
import {clone} from "../../../util/objectUtil";
import TabMenu from "../../controls/TabMenu";
import Loading from "../../controls/Loading";
import {formatDateForHumans} from "../../../util/dateUtil";
import AdvancedTable from "../../controls/AdvancedTable";
import {WorkerData, WorkerDataPage} from "../../../type/WorkerData";
import WorkerStateControl from "../../controls/WorkerStateControl";

function WorkersTab({onAlert, onResetAlerts}: BasicComponentProps) {
	const [workers, setWorkers] = useState<WorkerDataPage | null>(null);
	const [paging, setPaging] = useState<Paging>(clone(DEFAULT_PAGING));

	const search = (text: string) => {
		paging.search = text;
		setPaging(clone(paging));
	}

	const refreshWorkers = () => {
		setWorkers(null);
		loadWorkers(paging)
			.then(
				(workers: WorkerDataPage) => {
					onResetAlerts();
					setWorkers(workers);
				},
				(err) => onAlert({type: 'danger', title: 'Error', message: String(err)})
			);
	};

	useEffect(refreshWorkers, [paging]);

	const onDelete = (worker: WorkerData) => {
		if (!window.confirm("Really delete this worker?")) {
			return;
		}
		deleteWorker(Number(worker.id))
			.then(
				() => {
					refreshWorkers();
					onAlert({
						type: 'success',
						title: 'Worker Deleted',
						message: `Deleted Worker ${worker.name}.`
					});
				},
				(err) => onAlert({type: 'danger', title: 'Error', message: String(err)})
			);
	};

	const header = [
		{name: 'id', label: 'ID'},
		{name: 'workerType', label: 'Type'},
		{name: 'name', label: 'Name'},
		{name: 'ip', label: 'IP'},
		{name: 'version', label: 'Version'},
		{name: 'workerState', label: 'State'},
		{name: 'lastHeartbeat', label: 'Heartbeat'},
		{name: '', label: 'Actions'}
	];

	return (
		<div>
			<TabMenu onRefreshButtonClick={refreshWorkers} onSearchButtonClick={search}>
			</TabMenu>
			<AdvancedTable
				header={header}
				onPagingChanged={setPaging}
				totalPages={workers ? workers.totalPages : 0}
				totalItems={workers ? workers.totalElements : 0}
				paging={paging}
			>
				{
					(workers === null) ? <tr>
							<td colSpan={header.length}><Loading/></td>
						</tr> :
						workers.content.map((worker, index) => {
							return (
								<tr key={index}>
									<td>{worker.id}</td>
									<td>{worker.workerType}</td>
									<td>{worker.name}</td>
									<td>{worker.ip}</td>
									<td>{worker.version}</td>
									<td><WorkerStateControl state={worker.workerState}/></td>
									<td>{formatDateForHumans(worker.lastHeartbeat)}</td>
									<td>
										<div className="d-flex flex-row gap-2">
											<Button variant="danger" size="sm" onClick={() => onDelete(worker)}>Delete</Button>
										</div>
									</td>
								</tr>
							);
						})
				}
			</AdvancedTable>
		</div>
	);
}

export default WorkersTab;
