import React from 'react';
import {Badge, Spinner} from "react-bootstrap";

export type WorkerStateProps = {
	state: string;
};

const STATE_MAPPING: Map<string, string> = new Map<string, string>();
STATE_MAPPING.set('Sleeping', 'success');
STATE_MAPPING.set('Working', 'primary');
STATE_MAPPING.set('Dead', 'danger');

function WorkerStateControl(props: WorkerStateProps) {
	const background: string = STATE_MAPPING.get(props.state) || 'primary';
	const text = ['warning', 'light'].includes(background) ? 'dark' : 'light';
	return (
		<div className="d-flex gap-1 m-auto align-items-center">
			{props.state === 'Working' &&
				<Spinner animation="grow" role="status" size="sm"/>
			}
			<Badge bg={background} text={text}>{props.state}</Badge>
		</div>
	);
}

export default WorkerStateControl;
