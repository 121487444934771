export function formatJsonForHumans(val: any): string {
	if (val === undefined) return '';
	if (typeof val !== 'string') return formatJsonForHumans(JSON.stringify(val));
	if (val.trim().length === 0) return '';
	try {
		return JSON.stringify(JSON.parse(val), null, 4);
	} catch (e) {
		return '';
	}
}
