import React from 'react';
import {Card} from 'react-bootstrap';
import {WorkerDataPage} from "../../../type/WorkerData";
import Loading from "../../controls/Loading";
import WorkerStateControl from "../../controls/WorkerStateControl";

export type WorkersOverviewProps = {
	workers: WorkerDataPage | null;
};

function WorkersOverview({workers}: WorkersOverviewProps) {
	if (workers === null) return <Loading/>;

	if (workers.totalElements === 0) return <span>No workers available.</span>

	return (
		<div>
			<div className="m-2">Total Workers: {workers.totalElements}</div>
			<div className="d-flex flex-wrap gap-3">
				{
					workers.content.map(
						(w, index) =>
							<Card key={index} className="text-center">
								<Card.Header>
									{w.name}
								</Card.Header>
								<Card.Footer>
									<div className="d-flex text-center align-items-center">
										<WorkerStateControl state={w.workerState}/>
									</div>
								</Card.Footer>
							</Card>
					)
				}
			</div>
		</div>
	);
}

export default WorkersOverview;
