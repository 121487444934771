import React from 'react';
import {Container, Spinner} from "react-bootstrap";

export type LoadingProps = {
	message?: string;
};

function Loading({message}: LoadingProps) {
	const label = message || 'Loading...';
	return (
		<Container className="loading text-center p-3">
			<Spinner animation="border" role="status">
				<span className="visually-hidden">{label}</span>
			</Spinner>
			<div>
				{label}
			</div>
		</Container>
	);
}

export default Loading;
