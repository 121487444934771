import React from 'react';
import {BsCalendar2RangeFill} from "react-icons/bs";
import {Button, Spinner} from "react-bootstrap";
import {Auth} from '../util/authenticationUtil';
import {safeTruncate} from "../util/stringUtil";
import SchedulerStateControl from "./controls/SchedulerStateControl";
import {BasicComponentProps} from "../type/BasicComponentProps";

export type HeaderProps = BasicComponentProps & {
	auth: Auth | null;
	version: string | undefined | null;
	onLogOut: () => any;
};

function Header({auth, version, onAlert, onResetAlerts, onLogOut}: HeaderProps) {
	return (
		<header className="app-header">
			<div className="d-flex justify-content-between">
				<div>
					<SchedulerStateControl onAlert={onAlert} onResetAlerts={onResetAlerts}/>
				</div>
				<div className="d-flex ">
					<div className="p-2">
						{
							version ?
								<span>v.{safeTruncate(version, 16)}</span>
								:
								version === null ?
									<Spinner animation="border" role="status" size="sm">
										<span className="visually-hidden">loading...</span>
									</Spinner>
									:
									<span className="text-danger">Error</span>
						}
					</div>
					{
						auth ?
							<Button variant="outline-warning" onClick={onLogOut}>Log&nbsp;Out</Button>
							:
							<span className="text-danger">Not Authenticated</span>
					}
				</div>
			</div>
			<div>
				<h1 className="d-flex align-items-center pt-0 mt-0 pb-4">
					<BsCalendar2RangeFill className="me-4"/>
					<div>
						Incomaker Job Manager
					</div>
				</h1>

			</div>
		</header>
	);
}

export default Header;
