import React, {useEffect, useState} from 'react';
import {Badge, Button} from "react-bootstrap";
import {BsPause, BsPlay} from 'react-icons/bs';
import {BasicComponentProps} from "../../type/BasicComponentProps";
import {loadSchedulerState, updateSchedulerState} from "../../util/restClient";

export type SchedulerStateProps = BasicComponentProps & {};

function SchedulerStateControl({onAlert}: SchedulerStateProps) {
	const [enabled, setEnabled] = useState<boolean | undefined>(undefined);

	const loadState = () => {
		loadSchedulerState()
			.then(
				(s: boolean) => {
					setEnabled(s);
				},
				(err) => onAlert({type: 'danger', title: 'Error', message: String(err)})
			);
	};

	useEffect(() => {
		loadState();
		const intervalHandle: NodeJS.Timer = setInterval(loadState, 3000);
		return () => {
			window.clearInterval(intervalHandle);
		};
	}, []);

	const switchState = async () => {
		const state = await updateSchedulerState(!enabled);
		setEnabled(state);
	}

	return (
		<div className="d-flex align-items-center gap-2">
			<Badge
				className="p-2"
				bg={enabled ? 'success' : 'danger'}
			>
				{enabled ? 'RUNNING' : 'PAUSED'}
			</Badge>
			<Button
				variant={enabled ? 'outline-danger' : 'outline-success'}
				size="sm"
				onClick={switchState}
				title={enabled ? 'Pause scheduler' : 'Unpause scheduler'}
				className="cursor-pointer"
			>
				<div className="d-flex">
					{enabled ? <BsPause size={20}/> : <BsPlay size={20}/>}
				</div>
			</Button>
		</div>
	);
}

export default SchedulerStateControl;
