import React from 'react';
import {Stack} from 'react-bootstrap';
import ExecutionStateControl, {getStateVariant, isStateRunning} from "./ExecutionStateControl";
import ProgressBarControl from "./ProgressBarControl";
import {ExecutionResult} from "../../type/ExecutionData";

export type ExecutionResultWithProgressControlProps = {
	executionResult?: ExecutionResult | null;
};

function ExecutionResultWithProgressControl({executionResult}: ExecutionResultWithProgressControlProps) {
	if (executionResult === null || executionResult === undefined) return <></>
	const percent = (executionResult.progress === null || executionResult.progress === undefined) ? null : Math.round(executionResult.progress * 100);
	const percentLabel = percent === null ? '?' : `${percent}%`;
	return <Stack direction="vertical">
		<Stack direction="vertical" gap={1} className="align-items-center">
			<ExecutionStateControl state={executionResult.executionState}/>
			<span>{percentLabel}</span>
		</Stack>
		<ProgressBarControl
			progress={executionResult.progress}
			animated={isStateRunning(executionResult.executionState)}
			variant={getStateVariant(executionResult.executionState)}
		/>
	</Stack>
}

export default ExecutionResultWithProgressControl;
