import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Modal, Row} from 'react-bootstrap';
import {clone} from "../../../util/objectUtil";
import {BasicComponentProps} from "../../../type/BasicComponentProps";
import {loadPluginImportModes, loadPluginImportTypes} from "../../../util/restClient";
import {ImportPluginJobArgument} from "../../../type/ImportPluginJobArgument";
import {formIntegerHelper, integerToString} from "../../../util/numberUtil";
import {formatDateForHumans} from "../../../util/dateUtil";

export type CustomPluginImportFormProps = BasicComponentProps & {
	data: ImportPluginJobArgument;
	onConfirmed: (data: ImportPluginJobArgument) => any;
	onCancelled: () => any;
};

function CustomPluginImportForm({data, onConfirmed, onCancelled, onAlert}: CustomPluginImportFormProps) {
	const [importTypes, setImportTypes] = useState<string[] | null>(null);
	const [importModes, setImportModes] = useState<string[] | null>(null);
	const [formData, setFormData] = useState<ImportPluginJobArgument>(data);

	const loadImportTypes = () => {
		loadPluginImportTypes()
			.then(
				setImportTypes,
				(err) => onAlert({type: 'danger', title: 'Error', message: String(err)})
			);
	}

	useEffect(loadImportTypes);

	const loadImportModes = () => {
		loadPluginImportModes()
			.then(
				setImportModes,
				(err) => onAlert({type: 'danger', title: 'Error', message: String(err)})
			);
	}

	useEffect(loadImportModes);

	const updateField = (action: () => any) => {
		action();
		setFormData(clone(formData));
	}

	return (
		<Modal show={true} onHide={onCancelled} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>Custom Plugin Import</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={() => onConfirmed(formData)}>
					<Row>
						<Col>
							<Form.Group className="mb-3" controlId="id">
								<Form.Label>Plugin ID</Form.Label>
								<Form.Control
									type="text"
									disabled={true}
									value={formData.workerParam.pluginId}>
								</Form.Control>
							</Form.Group>
							<Form.Group className="mb-3" controlId="jobType">
								<Form.Label>Type</Form.Label>
								<Form.Select
									value={formData.workerParam.importCommand}
									onChange={(e) => updateField(() => formData.workerParam.importCommand = e.target.value)}
								>
									{
										importTypes ?
											<>
												{importTypes.map((it) => <option key={it} value={it}>{it}</option>)}
											</> : <option>loading...</option>
									}
								</Form.Select>
							</Form.Group>
							<Form.Group className="mb-3" controlId="isVipQueue">
								<Form.Switch
									label="Is VIP"
									checked={formData.vipQueue}
									onChange={(e) => updateField(() => formData.vipQueue = !formData.vipQueue)}
								/>
								<Form.Text muted>
									VIP jobs are queued in separate queue and are processed by dedicated workers.
								</Form.Text>
							</Form.Group>
							<Form.Group className="mb-3" controlId="isPriorityMessage">
								<Form.Switch
									label="Priority Message"
									checked={formData.priorityMessage}
									onChange={(e) => updateField(() => formData.priorityMessage = !formData.priorityMessage)}
								/>
								<Form.Text muted>
									Priority messages will skip others in the queue and will be processed sooner.
								</Form.Text>
							</Form.Group>
							<Form.Group className="mb-3" controlId="jobMode">
								<Form.Label>Mode</Form.Label>
								<Form.Select
									value={formData.workerParam.importMode}
									onChange={(e) => updateField(() => formData.workerParam.importMode = e.target.value)}
								>
									{
										importModes ?
											<>
												{importModes.map((im) => <option key={im} value={im}>{im}</option>)}
											</> : <option>loading...</option>
									}
								</Form.Select>
							</Form.Group>
							{
								formData.workerParam.importMode === 'Custom' ?
									<Form.Group className="mb-3" controlId="since">
										<Form.Label>Since</Form.Label>
										<Form.Control
											type="datetime-local"
											value={formData.workerParam.since ? formatDateForHumans(formData.workerParam.since) : ""}
											onChange={(e) => updateField(() => formData.workerParam.since = new Date(e.target.value))}
										/>
									</Form.Group>
									: <></>
							}
						</Col>
						<Col>
							<Form.Group className="mb-3" controlId="itemId">
								<Form.Label>Item ID</Form.Label>
								<Form.Control
									type="text"
									value={formData.workerParam.itemId}
									onChange={(e) => updateField(() => formData.workerParam.itemId = e.target.value)}
								/>
								<Form.Text muted>
									Download a specific item by ID. When this is filled, other parameters will be ignored.
								</Form.Text>
							</Form.Group>
							<Form.Group className="mb-3" controlId="total">
								<Form.Label>Page Size</Form.Label>
								<Form.Control
									type="text"
									value={integerToString(formData.workerParam.pageSize)}
									onChange={(e) => updateField(() => formData.workerParam.pageSize = formIntegerHelper(e.target.value, formData.workerParam.pageSize))}
								/>
								<Form.Text muted>
									Number of items per page.
								</Form.Text>
							</Form.Group>
							<Form.Group className="mb-3" controlId="page">
								<Form.Label>Start Page</Form.Label>
								<Form.Control
									type="text"
									value={integerToString(formData.workerParam.startPage)}
									onChange={(e) => updateField(() => formData.workerParam.startPage = formIntegerHelper(e.target.value, formData.workerParam.startPage))}
								/>
								<Form.Text muted>
									Page to start the import from. First page is 0 (default).
								</Form.Text>
							</Form.Group>
							<Form.Group className="mb-3" controlId="limit">
								<Form.Label>Max Pages</Form.Label>
								<Form.Control
									type="text"
									value={integerToString(formData.workerParam.maxPages)}
									onChange={(e) => updateField(() => formData.workerParam.maxPages = formIntegerHelper(e.target.value, formData.workerParam.maxPages))}
								/>
								<Form.Text muted>
									Maximum number of imported pages. Default is 1000.
								</Form.Text>
							</Form.Group>
							<Form.Group className="mb-3" controlId="forceSubscriptionReimport">
								<Form.Switch
									label="Force Subscription Tags Reimport"
									checked={formData.workerParam.forceSubscriptionReimport}
									onChange={(e) => updateField(() => formData.workerParam.forceSubscriptionReimport = !formData.workerParam.forceSubscriptionReimport)}
								/>
							</Form.Group>
							<Form.Group className="mb-3" controlId="downloadLongDescriptions">
								<Form.Switch
									label="Download Long Descriptions"
									checked={formData.workerParam.downloadLongDescriptions}
									onChange={(e) => updateField(() => formData.workerParam.downloadLongDescriptions = !formData.workerParam.downloadLongDescriptions)}
								/>
							</Form.Group>
							<Form.Group className="mb-3" controlId="forceFixSex">
								<Form.Switch
									label="Force Fix Sex"
									checked={formData.workerParam.forceFixSex}
									onChange={(e) => updateField(() => formData.workerParam.forceFixSex = !formData.workerParam.forceFixSex)}
								/>
							</Form.Group>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={onCancelled}>Close</Button>
				<Button variant="success" type="submit" onClick={() => onConfirmed(formData)}>Confirm</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default CustomPluginImportForm;
