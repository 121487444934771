export function formatNumber(n: number, digits = 2) {
	const s = String(n);
	return s.padStart(digits, '0');
}

export function formatDateForHumans(d: Date | string | null | undefined): string {
	if (!d) return "";
	if (typeof d === 'string') {
		return formatDateForHumans(new Date(d));
	}

	const year = d.getFullYear();
	const month = formatNumber(d.getMonth() + 1);
	const day = formatNumber(d.getDate());
	const hours = formatNumber(d.getHours());
	const minutes = formatNumber(d.getMinutes());
	const seconds = formatNumber(d.getSeconds());
	const nano = d.getMilliseconds() > 0 ? '.' + formatNumber(d.getMilliseconds(), 3) : '';
	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}${nano}`;
}
