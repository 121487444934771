import React, {useEffect, useState} from 'react';
import {getMessageQueueSize, purgeMessageQueue} from "../../../util/restClient";
import {BasicComponentProps} from "../../../type/BasicComponentProps";
import {Button, Card} from 'react-bootstrap';
import {integerToString} from "../../../util/numberUtil";

export type RabbitQueueProps = BasicComponentProps & {
	queueName: string;
};

function RabbitQueueControl({queueName, onAlert}: RabbitQueueProps) {
	const [queueSize, setQueueSize] = useState<number | null>(null);

	const purgeQueue = () => {
		if (!window.confirm(`This will delete all messages in queue ${queueName}. Proceed?`)) {
			return;
		}
		purgeMessageQueue(queueName)
			.then(
				(result) => {
					onAlert({
						type: 'success',
						title: 'Queue purged',
						message: `Purged ${result} messages from queue ${queueName}.`
					});
				},
				(err) => onAlert({type: 'danger', title: 'Error', message: String(err)})
			);
	};

	const loadQueueSize = () => {
		getMessageQueueSize(queueName)
			.then(
				(result) => {
					setQueueSize(Number(result));
				},
				(err) => onAlert({type: 'danger', title: 'Error', message: String(err)})
			);
	};

	useEffect(() => {
		loadQueueSize();
		const intervalHandle: NodeJS.Timer = setInterval(loadQueueSize, 3000);
		return () => {
			window.clearInterval(intervalHandle);
		};
	}, []);

	return (
		<Card className="text-center">
			<Card.Header>
				{queueName}
			</Card.Header>
			<Card.Body>
				{queueSize == null ? '?' : integerToString(queueSize)}
			</Card.Body>
			<Card.Footer>
				<Button variant="danger" size="sm" onClick={purgeQueue} disabled={queueSize == null || queueSize <= 0}>Purge</Button>
			</Card.Footer>
		</Card>
	);
}

export default RabbitQueueControl;
