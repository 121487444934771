import React from 'react';
import {ProgressBar} from 'react-bootstrap';

export type ProgressBarControlProps = {
	/* 0 .. 1 */
	progress?: number | null;
	animated?: boolean | null;
	variant?: string | null;
};

function ProgressBarControl({progress, animated, variant}: ProgressBarControlProps) {
	const percent = (progress === null || progress === undefined) ? 0 : Math.round(progress * 100);
	const actualVariant = (variant === null || variant === undefined) ? 'primary' : variant;
	return <ProgressBar now={percent} animated={animated === true} variant={actualVariant}/>
}

export default ProgressBarControl;
