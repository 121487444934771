import React, {useEffect, useState} from 'react';
import {ScheduleData} from "../../../type/ScheduleData";
import {Button, Form, Modal} from 'react-bootstrap';
import {clone} from "../../../util/objectUtil";
import {formatDateForHumans} from "../../../util/dateUtil";
import {loadScheduleTypes} from "../../../util/restClient";
import {BasicComponentProps} from "../../../type/BasicComponentProps";

export type SchedulesGridProps = BasicComponentProps & {
	data: ScheduleData;
	onSaved: (data: ScheduleData) => any;
	onDeleted?: (data: ScheduleData) => any;
	onCancelled: () => any;
};

function ScheduleForm({data, onSaved, onDeleted, onCancelled, onAlert}: SchedulesGridProps) {
	const [scheduleTypes, setScheduleTypes] = useState<string[] | null>(null);
	const [formData, setFormData] = useState<ScheduleData>(clone(data));

	const loadTypes = () => {
		loadScheduleTypes()
			.then(
				setScheduleTypes,
				(err) => onAlert({type: 'danger', title: 'Error', message: String(err)})
			);
	}

	useEffect(loadTypes, []);

	const updateField = (action: () => any) => {
		action();
		setFormData(clone(formData));
	}

	return (
		<Modal show={true} onHide={onCancelled}>
			<Modal.Header closeButton>
				<Modal.Title>Schedule for <strong>{formData.job.name}</strong></Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={() => onSaved(formData)}>
					<Form.Group className="mb-3" controlId="pluginId">
						<Form.Label>ID</Form.Label>
						<Form.Control
							type="text"
							disabled={true}
							value={String(formData.id)}>
						</Form.Control>
					</Form.Group>
					<Form.Group className="mb-3" controlId="pluginId">
						<Form.Label>Type</Form.Label>
						<Form.Select
							aria-label="Default select example"
							value={formData.scheduleType}
							onChange={(e) => updateField(() => formData.scheduleType = e.target.value)}
						>
							{
								scheduleTypes ?
									<>
										{scheduleTypes.map((st) => <option key={st} value={st}>{st}</option>)}
									</> : <option>loading...</option>
							}
						</Form.Select>
					</Form.Group>
					<Form.Group className="mb-3" controlId="active">
						<Form.Switch
							label="Is Active"
							checked={formData.active}
							onChange={(e) => updateField(() => formData.active = !formData.active)}
						/>
					</Form.Group>
					<Form.Group className="mb-3" controlId="pluginId">
						<Form.Label>Start</Form.Label>
						<Form.Control
							type="datetime-local"
							value={formData.startTime ? formatDateForHumans(formData.startTime) : ""}
							onChange={(e) => updateField(() => formData.startTime = new Date(e.target.value))}
						/>
					</Form.Group>
					<Form.Group className="mb-3" controlId="pluginId">
						<Form.Label>Interval (ms)</Form.Label>
						<Form.Control
							type="text"
							value={formData.intervalMs || ''}
							onChange={(e) => updateField(() => formData.intervalMs = Number(e.target.value))}
						/>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={onCancelled}>Close</Button>
				<Button variant="danger" disabled={onDeleted === undefined}
					onClick={() => onDeleted === undefined ? null : onDeleted(formData)}>Delete</Button>
				<Button variant="success" onClick={() => onSaved(formData)}>Save</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default ScheduleForm;
