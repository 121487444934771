import React from 'react';
import {Badge} from "react-bootstrap";

export const STATES_RUNNING = [
	'Running',
	'WaitingForWorker',
	'WorkerRunning',
	'WaitingForCallback',
	'CancellationPending'
];

export function isStateRunning(executionState: string) {
	return STATES_RUNNING.includes(executionState);
}

export type ExecutionStateProps = {
	state: string | null | undefined;
};

const STATE_MAPPING: Map<string, string> = new Map<string, string>();
STATE_MAPPING.set('NotStarted', 'secondary');
STATE_MAPPING.set('WaitingForWorker', 'secondary');
STATE_MAPPING.set('Running', 'primary');
STATE_MAPPING.set('WaitingForCallback', 'primary');
STATE_MAPPING.set('CancellationPending', 'secondary');
STATE_MAPPING.set('Cancelled', 'secondary');
STATE_MAPPING.set('Timeout', 'light');
STATE_MAPPING.set('Success', 'success');
STATE_MAPPING.set('Warning', 'warning');
STATE_MAPPING.set('Failed', 'danger');
STATE_MAPPING.set('WorkerDied', 'danger');
STATE_MAPPING.set('WorkerFellAsleep', 'danger');

export function getStateVariant(executionState: string) {
	return STATE_MAPPING.get(executionState) || 'primary';
}

function ExecutionStateControl({state}: ExecutionStateProps) {
	if (!state) return <></>;
	const background: string = getStateVariant(state);
	const text = ['warning', 'light'].includes(background) ? 'dark' : 'light';
	return <Badge bg={background} text={text}>{state}</Badge>;
}

export default ExecutionStateControl;
