import React, {useEffect, useState} from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import {clone} from "../../../util/objectUtil";
import {BasicComponentProps} from "../../../type/BasicComponentProps";
import {JobData} from '../../../type/JobData';
import {loadJobTypes} from "../../../util/restClient";
import {formatJsonForHumans} from "../../../util/jsonUtil";

export type JobFormProps = BasicComponentProps & {
	data: JobData;
	onSaved: (data: JobData) => any;
	onDeleted: (data: JobData) => any;
	onCancelled: () => any;
};

function JobForm({data, onSaved, onDeleted, onCancelled, onAlert}: JobFormProps) {
	data.arguments = formatJsonForHumans(data.arguments);
	const [jobTypes, setJobTypes] = useState<string[] | null>(null);
	const [formData, setFormData] = useState<JobData>(clone(data));

	const loadTypes = () => {
		loadJobTypes()
			.then(
				setJobTypes,
				(err) => onAlert({type: 'danger', title: 'Error', message: String(err)})
			);
	}

	useEffect(loadTypes, []);

	const updateField = (action: () => any) => {
		action();
		setFormData(clone(formData));
	}

	return (
		<Modal show={true} onHide={onCancelled}>
			<Modal.Header closeButton>
				<Modal.Title>Job - {formData.name}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={() => onSaved(formData)}>
					<Form.Group className="mb-3" controlId="id">
						<Form.Label>ID</Form.Label>
						<Form.Control
							type="text"
							disabled={true}
							value={formData.id}>
						</Form.Control>
					</Form.Group>
					<Form.Group className="mb-3" controlId="jobType">
						<Form.Label>Type</Form.Label>
						<Form.Select
							value={formData.jobType}
							onChange={(e) => updateField(() => formData.jobType = e.target.value)}
						>
							{
								jobTypes ?
									<>
										{jobTypes.map((jt) => <option key={jt} value={jt}>{jt}</option>)}
									</> : <option>loading...</option>
							}
						</Form.Select>
					</Form.Group>
					<Form.Group className="mb-3" controlId="name">
						<Form.Label>Name</Form.Label>
						<Form.Control
							type="text"
							value={formData.name}
							onChange={(e) => updateField(() => formData.name = e.target.value)}
						/>
					</Form.Group>
					<Form.Group className="mb-3" controlId="arguments">
						<Form.Label>Arguments</Form.Label>
						<Form.Control
							as="textarea"
							rows={3}
							value={formData.arguments}
							onChange={(e) => updateField(() => formData.arguments = e.target.value)}
						/>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={onCancelled}>Close</Button>
				<Button variant="danger" onClick={() => onDeleted(formData)}>Delete</Button>
				<Button variant="success" type="submit" onClick={() => onSaved(formData)}>Save</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default JobForm;
