import React from 'react';
import {Button, Toast} from "react-bootstrap";
import {BsFillSquareFill} from 'react-icons/bs';
import {UserAlert, UserAlerts} from "../../type/UserAlert";

export type AlertBoxProps = {
	alerts: UserAlerts;
	onRemoveAlert: (alert: UserAlert) => void;
	onResetAlerts: () => void;
};

function AlertBox({alerts, onResetAlerts, onRemoveAlert}: AlertBoxProps) {
	return (
		<div className="alert-box">
			{
				alerts.map(
					(alert: UserAlert, index) => {
						return <Toast
							key={index}
							bg={alert.type}
							onClose={() => onRemoveAlert(alert)}
							className="m-2"
						>
							<Toast.Header className="justify-content-between">
								<BsFillSquareFill/>
								<strong className="ms-2 me-auto">{alert.title}</strong>
							</Toast.Header>
							<Toast.Body>{alert.message}</Toast.Body>
						</Toast>
					}
				)
			}
			{
				alerts.length > 0 && (
					<div className="d-flex justify-content-center m-2">
						<Button size="sm" variant="outline-secondary" onClick={onResetAlerts}>Clear All</Button>
					</div>
				)
			}
		</div>
	);
}

export default AlertBox;
