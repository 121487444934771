import React from 'react';

export type AsyncCallbackStateProps = {
	state: string | null | undefined;
};

const STATE_MAPPING: Map<string, string> = new Map<string, string>();
STATE_MAPPING.set('Waiting', 'primary');
STATE_MAPPING.set('Success', 'success');
STATE_MAPPING.set('Failed', 'danger');

function AsyncCallbackStateControl({state}: AsyncCallbackStateProps) {
	if (!state) return <></>;
	const text = STATE_MAPPING.get(state) || 'secondary';
	return (
		<div className={`d-flex gap-1 align-items-center text-${text}`}>
			{state}
		</div>
	);
}

export default AsyncCallbackStateControl;
